@import "~@fontsource/roboto/scss/mixins";
@include fontFace(
  $weight: 300,
  $display: swap
);
@include fontFace(
  $weight: 400,
  $display: swap
);
@include fontFace(
  $weight: 500,
  $display: swap
);
@include fontFace(
  $weight: 700,
  $display: swap
);

@import "~@fontsource/material-icons/scss/mixins";
@include fontFaceCustom(
  $weight: 400,
  $display: swap
);

body,
html {
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, sans-serif;
  height: 100%;
}

#app-portal {
  height: 100%;
}
